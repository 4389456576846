import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from "axios";
import { showMessage, toastr } from '../utils';
import { api_url } from '../utils/keys';
import { pageActions } from './page.slice';
import { userActions } from './user.slice';

// create slice

const name = 'more';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({ name, initialState, reducers });

// exports

export const moreActions = { ...slice.actions, ...extraActions };
export const moreReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        events: [],
        notices: [],
        messages: [],
        customers: [],
        depositList: [],
        withdrawList: [],
        pointExchangeList: []
    }
}

function createReducers() {
    return {
        setEvents,
        setNotices,
        setMessages,
        setCustomers,
        setDepositList,
        setWithdrawList,
        setPointExchangeList,
    };

    function setEvents(state, action) {
        state.events = action.payload;
    }

    function setNotices(state, action) {
        state.notices = action.payload;
    }

    function setMessages(state, action) {
        state.messages = action.payload;
    }

    function setCustomers(state, action) {
        state.customers = action.payload;
    }

    function setDepositList(state, action) {
        state.depositList = action.payload;
    }

    function setWithdrawList(state, action) {
        state.withdrawList = action.payload;
    }

    function setPointExchangeList(state, action) {
        state.pointExchangeList = action.payload;
    }
}

function createExtraActions() {
    // const baseUrl = `${api_url}/users`;

    return {
        getEvents: getEvents(),
        getNotices: getNotices(),
        getMessages: getMessages(),
        getCustomers: getCustomers(),
        getDepositList: getDepositList(),
        getWithdrawList: getWithdrawList(),
        getPointExchangeList: getPointExchangeList(),
    };

    function getEvents() {
        return createAsyncThunk(
            `${name}/getEvents`,
            async function (params, { dispatch }) {
                try {
                    const res = await axios.post(api_url + "/api/post/list", params);
                    const data = res.data;
                    showMessage(data.message);

                    if (data.success) {
                        dispatch(moreActions.setEvents(data.list));
                    }
                } catch (error) {
                    console.error(error);
                    if (error.response && (error.response.status === 401)) {
                        return dispatch(userActions.logout());
                    }
                    else {
                        toastr.warning("잠시후 다시 시도해주세요.");
                    }
                }
            }
        );
    }


    function getNotices() {
        return createAsyncThunk(
            `${name}/getNotices`,
            async function (params, { dispatch }) {
                try {
                    const res = await axios.post(api_url + "/api/notification/list", params);
                    const data = res.data;
                    showMessage(data.message);

                    if (data.success) {
                        dispatch(moreActions.setNotices(data.list));
                        dispatch(pageActions.setTotalPage(data.totalPage));
                    }
                } catch (error) {
                    console.error(error);
                    if (error.response && (error.response.status === 401)) {
                        return dispatch(userActions.logout());
                    }
                    else {
                        toastr.warning("잠시후 다시 시도해주세요.");
                    }
                }
            }
        );
    }


    function getMessages() {
        return createAsyncThunk(
            `${name}/getMessages`,
            async function (params, { dispatch }) {
                try {
                    const res = await axios.post(api_url + "/api/notes/getList", params);
                    const data = res.data;
                    showMessage(data.message);

                    if (data.success) {
                        dispatch(moreActions.setMessages(data.notes));
                        dispatch(pageActions.setTotalPage(data.totalPage));
                    }
                } catch (error) {
                    console.error(error);
                    if (error.response && (error.response.status === 401)) {
                        return dispatch(userActions.logout());
                    }
                    else {
                        toastr.warning("잠시후 다시 시도해주세요.");
                    }
                }
            }
        );
    }


    function getCustomers() {
        return createAsyncThunk(
            `${name}/getCustomers`,
            async function (params, { dispatch }) {
                try {
                    const res = await axios.post(api_url + "/api/question/getList", params);
                    const data = res.data;
                    showMessage(data.message);

                    if (data.success) {
                        dispatch(moreActions.setCustomers(data.questions));
                        dispatch(pageActions.setTotalPage(data.totalPage));
                    }
                } catch (error) {
                    console.error(error);
                    if (error.response && (error.response.status === 401)) {
                        return dispatch(userActions.logout());
                    }
                    else {
                        toastr.warning("잠시후 다시 시도해주세요.");
                    }
                }
            }
        );
    }


    function getDepositList() {
        return createAsyncThunk(
            `${name}/getDepositList`,
            async function (params, { dispatch }) {
                try {
                    const res = await axios.post(api_url + "/api/money/getHistory", params);
                    const data = res.data;
                    showMessage(data.message);
                    if (data.success) {
                        dispatch(moreActions.setDepositList(data.charge_histories));
                        dispatch(pageActions.setTotalPage(data.totalPage));
                    }
                } catch (error) {
                    console.error(error);
                    if (error.response && (error.response.status === 401)) {
                        return dispatch(userActions.logout());
                    }
                    else {
                        toastr.warning("잠시후 다시 시도해주세요.");
                    }
                }
            }
        );
    }


    function getWithdrawList() {
        return createAsyncThunk(
            `${name}/getWithdrawList`,
            async function (params, { dispatch }) {
                try {
                    const res = await axios.post(api_url + "/api/money/getHistory", params);
                    const data = res.data;
                    showMessage(data.message);
                    if (data.success) {
                        dispatch(moreActions.setWithdrawList(data.withdraw_histories));
                        dispatch(pageActions.setTotalPage(data.totalPage));
                    }
                } catch (error) {
                    console.error(error);
                    if (error.response && (error.response.status === 401)) {
                        return dispatch(userActions.logout());
                    }
                    else {
                        toastr.warning("잠시후 다시 시도해주세요.");
                    }
                }
            }
        );
    }


    function getPointExchangeList() {
        return createAsyncThunk(
            `${name}/getPointExchange`,
            async function (params, { dispatch }) {
                try {
                    const res = await axios.post(api_url + "/api/money/getrollinghistory", params);
                    const data = res.data;
                    showMessage(data.message);
                    if (data.success) {
                        dispatch(moreActions.setPointExchangeList(data.histories));
                        dispatch(pageActions.setTotalPage(data.totalPage));
                    }
                } catch (error) {
                    console.error(error);
                    if (error.response && (error.response.status === 401)) {
                        return dispatch(userActions.logout());
                    }
                    else {
                        toastr.warning("잠시후 다시 시도해주세요.");
                    }
                }
            }
        );
    }
}