
function SlotTop(props) {
  const { lists } = props
  return (
    <div className="rank-banner">
      <div className="rank">
        <div className="tit">
          <p className="btxt">슬롯<span>top</span></p>
          <p className="selects">
          </p>
        </div>
        <div className="list">
          <ul>
            {
              lists.map((list, index) => {
                console.log('list: ', list);
                return (<li key={index}></li>)
              })
            }
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SlotTop;
