import { useSelector } from 'react-redux';

import SlotTop from './dashboard/SlotTop';
import TopList from './dashboard/TopList';
import ImageMenu from './layout/ImageMenu';
import User from './layout/User';

function Dashboard() {
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  // if (socket.readyState === 1)
  //   socket.send(JSON.stringify({ MessageType: "random", data: "adkhfkdhfkhakdf"}))
  return (
    <div className="msec-core">
      <User nameKor={!isAuthenticated ? "로그인" : "게임"} name={!isAuthenticated ? "Login" : "GAME"}/>
      <div className="online-garkcom">
        <div className="leftrank-banner">

          <TopList title="홀덤" style={{ height: "273px" }} list={[]} />
          <TopList title="토너먼트" style={{}} list={[]} />

        </div>

        <div className="game-rank-recom">
          <div className="game-rank">
            <SlotTop lists={[]} />
            <div className="game">
              <div className="tit"> 게임<span>GAME</span> </div>
              <ImageMenu />
            </div>
          </div>

        </div>

      </div>
    </div>
  );
}

export default Dashboard;
