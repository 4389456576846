import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';

import ImageMenu from '../layout/ImageMenu';
import User from '../layout/User';
import Dot from './Dot';
import SlotItem from './SlotItem';
import { api_url } from '../../utils/keys';
import { isEmpty, toastr } from '../../utils';
import { userActions } from '../../store';
import Gameplay from './gameplay/Gameplay';

function TopGameSlot() {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.data);
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  const [slotList, setSlotList] = useState([]);

  const getSlotGameList = () => {
    if (isAuthenticated && !isEmpty(user)) {
      const parmas = {
        username: user.username,
        vendor_key: "topgame",
        api: "topgame",
        key: "topgame",
      }
      axios.post(api_url + "/api/slot/getgamelist", parmas)
        .then(res => {
          if (res.data.success) {
            if (!isEmpty(res.data.result)) {
              setSlotList(res.data.result);
            }
          }
        })
        .catch((error) => {
          if (error.response && (error.response.status === 401)) {
            return dispatch(userActions.logout());
          }
          toastr.warning("잠시후 다시 시도해주세요.");
        })
    }
  }

  const GameLaunch = (params) => {
    if (!isEmpty(user)) {
      if (params !== "LuckyWhale") {
        return toastr.warning("점검중입니다.");
      }
      const parmas = {
        username: user.username,
        vendor_key: "topgame",
        api: "topgame",
        key: "luckywhale",
        ipADD: "172.16.88.170",
        game_id: "vsluckywhale"
      }
      axios.post(api_url + "/api/slot/gamelaunch", parmas)
        .then(res => {
          // res.data
          // console.log('res.data: ', res.data);
          var width = 1920;
          var height = 1219;
          var toppx = window.screen.height / 2 - height / 2;
          var leftpx = window.screen.width / 2 - width / 2;

          var popup_name = "newWindow";
          //console.log("========팝업 링크 :", list.result.link);
          window.windowHandle = window.open(res.data.result.link, popup_name, "top=" + toppx + ", left=" + leftpx + ", width=" + width + ", height=" + height + " popup");
          //console.log("========팝업 :", window.windowHandle);
          if (!window.windowHandle) {
            toastr.warning("팝업이 블록되었습니다.");
          }
          // if (res.data.success) {
          // }
        })
    }
  }


  useEffect(() => {
    if (isAuthenticated) {
      getSlotGameList();
    }
  }, [isAuthenticated])

  return (
    <div className="msec-core">

      <User nameKor="슬롯" name="SLOT" />

      <div className="gostop">

        <div className="tit">탑게임 슬롯<span>TOPGAME SLOT</span></div>
        <div className="cont">
          <p className="vis">
            <img src="../images/main/slot_main.png" alt="S" />
          </p>
          <ImageMenu />


          <Gameplay />
        </div>
      </div>



      <div>
        <div className="starter-guide-non-ie" id="starterid">
          <div className="starter-shadow"></div>
          <div className="starter-content">
            <div data-starter-click="close" className="starter-close">X</div>
            <div className="starter-loading" id="loadingid">
              <div className="starter-spinner">
                <Dot />
                <Dot />
                <Dot />
                <Dot />
                <Dot />
                <Dot />
                <Dot />
                <Dot />
                <Dot />
                <Dot />
                <Dot />
                <Dot />
                <Dot />
                <Dot />
                <Dot />
                <Dot />
                <Dot />
                <Dot />
                <Dot />
                <Dot />
                <Dot />
                <Dot />
                <Dot />
                <Dot />
              </div>
              <div className="starter-loading-msg">탑게임을 이용할 수 있도록 게임 실행 환경을 구성합니다.</div>
            </div>

            <div className="starter-download" id="starterdownid">
              <Link to="" className="starter-download-btn">탑게임 스타터 다운로드</Link>
              <div className="starter-download-msg">게임이 실행되지 않는다면 탑게임 스타터 다운로드 버튼을 클릭하여 설치해 주세요.</div>
            </div>

            <Link to="" className="starter-download-bottom-btn">탑게임 스타터 다운로드</Link>
          </div>
        </div>
      </div>


      <div className="abatar-item">

        <div className="slot">
          <div className="tit">
            <p className="btxt">탑게임 슬롯<span>TOPGAME SLOT</span></p>
          </div>
          <div className="list">
            <ul>
              {
                slotList.map((menu, index) => {
                  return <SlotItem
                    image={menu.image}
                    name={menu.game_name}
                    key={index}
                    GameLaunch={GameLaunch} />
                })
              }
            </ul>
          </div>
        </div>


      </div>
    </div>
  );
}

export default TopGameSlot;
