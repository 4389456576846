import { Link } from 'react-router-dom';

function RightBox() {
  return (
    <div className="msec-ad">
      <Link to="">
        <img src="../images/Main/ad-sam.jpg" alt="광고"/>
      </Link>
		</div>
  );
}

export default RightBox;
