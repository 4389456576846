import { Fragment, useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

import Main from "./container/Main";
import PrivateRoute from './component/common/PrivateRoute';
import Dashboard from './component/Dashboard';
import TopGameSlot from './component/slot/TopGameSlot';
// import './App.css';

import { store, userActions } from './store';
import setAuthToken from './utils/setAuthToken';
import Register from './component/auth/Register';
import { history } from './helpers/history';
import Event from './component/more/event/Event';
import Notice from './component/more/notice/Notice';
import MyPage from './component/auth/MyPage';
import Deposit from './component/more/money/Deposit';
import Withdraw from './component/more/money/Withdraw';
import { getIpAddr, isEmpty } from './utils';
import { initSocket } from './utils/socket';
import PointExchange from './component/more/money/PointExchange';
import Customer from './component/more/customer/Customer';
import Message from './component/more/message/Message';

if (!isEmpty(localStorage.jwtToken)) {
  setAuthToken(localStorage.jwtToken);
  const currentTime = Date.now() / 1000;
  const decoded = jwt_decode(localStorage.jwtToken);

  store.dispatch(userActions.setUserInfo(decoded));
  store.dispatch(userActions.getUserInfo());
  // localStorage.setItem("exp", decoded.exp);
  localStorage.setItem("time", currentTime);
}

initSocket();

function App() {
  history.navigate = useNavigate();
  history.location = useLocation();

  useEffect(() => {
    getIpAddr();
  }, []);
  return (
    <Fragment>
      <NotificationContainer />
      <Routes>
        <Route path="/" element={<Main />}>
          <Route path="/" element={<Dashboard />}></Route>
          <Route path="/register" element={<Register />}></Route>
          <Route path="/topgame"
            element={
              <PrivateRoute>
                <TopGameSlot />
              </PrivateRoute>}>
          </Route>
          <Route path="/event"
            element={
              <PrivateRoute>
                <Event />
              </PrivateRoute>}>
          </Route>
          <Route path="/notice"
            element={
              <PrivateRoute>
                <Notice />
              </PrivateRoute>}>
          </Route>
          <Route path="/message"
            element={
              <PrivateRoute>
                <Message />
              </PrivateRoute>}>
          </Route>
          <Route path="/mypage"
            element={
              <PrivateRoute>
                <MyPage />
              </PrivateRoute>}>
          </Route>
          <Route path="/deposit"
            element={
              <PrivateRoute>
                <Deposit />
              </PrivateRoute>}>
          </Route>
          <Route path="/withdraw"
            element={
              <PrivateRoute>
                <Withdraw />
              </PrivateRoute>}>
          </Route>
          <Route path="/pointexchange"
            element={
              <PrivateRoute>
                <PointExchange />
              </PrivateRoute>}>
          </Route>
          <Route path="/cusotmer"
            element={
              <PrivateRoute>
                <Customer />
              </PrivateRoute>}>
          </Route>
          <Route path="/foldom" element={<Dashboard />}></Route>
        </Route>
      </Routes>
    </Fragment>
  );
}

export default App;