
function Pagenation(props) {
  const { page, totalPage, getPage } = props;
  const pageIndexCount = 5;
  let leftLimit = Number(page) - Math.floor(pageIndexCount / 2);
  let rightLimit = Number(page) + Math.floor(pageIndexCount / 2);
  if (leftLimit < 1) {
    rightLimit = rightLimit - leftLimit + 1;
    leftLimit = 1;
  }
  if (rightLimit > totalPage) {
    leftLimit -= (rightLimit - totalPage + 1);
    rightLimit = totalPage;
  }
  if (leftLimit < 1) leftLimit = 1;
  let pageIndexes = [];
  for (let i = leftLimit; i <= rightLimit; i++) pageIndexes.push(i);

  return (
    <div className="pagenation">
      <nav aria-label="Page navigation">
        <ul className="pagination pagination-warning d-flex justify-center">
          <li className="page-item first">
            <a className="page-link waves-effect" onClick={() => getPage(1)}>
              <i className="ti ti-chevrons-left ti-xs"></i>
            </a>
          </li>
          <li className="page-item prev">
            <a className="page-link waves-effect" onClick={() => getPage(Number(page) - 1)}>
              <i className="ti ti-chevron-left ti-xs"></i>
            </a>
          </li>
          {
            pageIndexes.map(pageIndex => {
              return (
                <li className={`page-item ${ pageIndex === Number(page) && 'active'}`} key={pageIndex}>
                <a className="page-link waves-effect" onClick={() => getPage(pageIndex)}>{pageIndex}</a>
              </li>
              )
            })
          }
          <li className="page-item next">
            <a className="page-link waves-effect" onClick={() => getPage(Number(page) + 1)}>
              <i className="ti ti-chevron-right ti-xs"></i>
            </a>
          </li>
          <li className="page-item last">
            <a className="page-link waves-effect" onClick={() => getPage(Number(totalPage))}>
              <i className="ti ti-chevrons-right ti-xs"></i>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Pagenation;
