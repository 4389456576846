import { useEffect, useState } from "react";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { convertMoneyStatus, convertMoneyStatusClase, dateFormation, isEmpty, no_comma, showMessage, toastr } from "../../../utils";
import Input from "../../auth/Input";
import { api_url } from "../../../utils/keys";
import User from "../../layout/User";
import { moreActions } from "../../../store";
import Pagenation from "../../common/Pagenation";

const rule = `
<p class="color-gray" style="display: flex;">
  <span style="font-size: 14px;">환전신청시 등록된 계좌와 다른 경우 환전처리가 거절됩니다.</span>
</p>
<p class="color-gray" style="display: flex;">
  <span style="font-size: 14px;">등록이 허용되지 않은 이용불가한 계좌의 경우</span>
</p>
<p class="color-gray" style="display: flex;">
  <span style="font-size: 14px;">고객센터롤 통해 환전계좌로 변경후 환전이 가능합니다.</span>
</p>
<p class="color-gray" style="display: flex;">
  <span style="font-size: 14px;">환전 최소 금액은 10,000원 이상 10,000원 단위로 가능합니다.</span>
</p>
<p class="color-gray" style="display: flex;">
  <span style="font-size: 14px;">환전신청 완료후 최소10초~ 최장 3분이내 환전처리 완료되십니다. 참고바랍니다.</span>
</p>
<p class="color-gray" style="display: flex;">
  <span style="font-size: 14px;">매일 23:30 부터 00:30까지는 은행 점검으로 인해 환전이 보류됩니다.</span>
</p>`

const moneyBtns = [
  {
    name: "10,000",
    value: 10000
  },
  {
    name: "30,000",
    value: 30000
  },
  {
    name: "50,000",
    value: 50000
  },
  {
    name: "100,000",
    value: 100000
  },
  {
    name: "300,000",
    value: 300000
  },
  {
    name: "500,000",
    value: 500000
  },
  {
    name: "1,000,000",
    value: 1000000
  },
  {
    name: "초기화",
    value: 0
  },
]

function Withdraw() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  const userData = useSelector(state => state.user.data);
  const history = useSelector(state => state.more.withdrawList);
  const totalPage = useSelector(state => state.page.totalPage);
  const [curPage, setCurPage] = useState(1);
  const [inputs, setInputs] = useState({
    withdrawPassword: '',
    money: 0,
  });
  const {
    withdrawPassword,
    money
  } = inputs;

  const [error, setError] = useState({})

  const onChange = (e) => {
    const { value, name } = e.target; // 우선 e.target 에서 name 과 value 를 추출
    setInputs({
      ...inputs, // 기존의 input 객체를 복사한 뒤
      [name]: value // name 키를 가진 값을 value 로 설정
    });
  };

  const onChangeMoney = (e) => {
    const { value, name } = e.target; // 우선 e.target 에서 name 과 value 를 추출
    if (isNaN(Number(no_comma(value)))) {
      setInputs({
        ...inputs, // 기존의 input 객체를 복사한 뒤
        [name]: 0 // name 키를 가진 값을 value 로 설정
      });
    }
    else {
      setInputs({
        ...inputs, // 기존의 input 객체를 복사한 뒤
        [name]: no_comma(value) // name 키를 가진 값을 value 로 설정
      });
    }
  };

  const onReset = () => {
    setInputs({
      withdrawPassword: '',
      money: 0
    })
  };

  const getHistory = (page = 1) => {
    if ((totalPage >= page) && (page > 0)) {
      setCurPage(page);
      dispatch(moreActions.getWithdrawList({
        typeStr: "withdraw",
        page: page,
        perPage: 10
      }));
    }
  }


  const onWithdraw = e => {
    e.preventDefault();

    const params = {
      amount: money,
      withdrawPassword,
      typeStr: "withdraw"
    }

    if (isEmpty(money) || (Number(money) === 0)) {
      return toastr.warning('머니를 입력해주세요.');
    }
    if (isEmpty(withdrawPassword) && (userData.agent_type === -1)) {
      return toastr.warning('환전비번을 입력해주세요.');
    }

    axios.post(api_url + "/api/money/exchange", params)
      .then(res => {
        const { success, errors, message } = res.data;
        showMessage(message);
        if (success) {
          onReset();
          getHistory();
        }
        else {
          console.log('error: ', error);
          setError(errors);
        }
      })
      .catch(err => {
        console.log(err)
        // if (err.response && err.response.status === 401) {
        //     props.logoutUser();
        // } else {
        toastr.warning('잠시후 다시 시도해주세요.');
        // }
      })
  }

  const addMoney = (value) => {
    if (value === 0) {
      setInputs({
        ...inputs,
        money: 0
      });
    }
    else {
      setInputs({
        ...inputs,
        money: Number(money) + value
      });
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      getHistory(1);
    }
  }, [isAuthenticated])

  return (
    <div className="msec-core">
      <div className="register-border">
        <User nameKor="환전" name="" />

        <div className="abatar-item">
          <div className="slot register my-page">
            <div className="list d-flex flex-wrap">

              <div className="register-div">

                <div className="w-100 rule-div pad15f" dangerouslySetInnerHTML={{ __html: rule }}>
                </div>

                <Input
                  labelName="환전비번"
                  checkLoginId={false}
                  errorMsg={""}
                  placeholder="환전비번을 입력해주세요."
                  type="password"
                  name="withdrawPassword"
                  inputClassName=""
                  onChange={onChange}
                  value={withdrawPassword} />

                {/* <Input
                  labelName="환전금액"
                  checkLoginId={false}
                  errorMsg={""}
                  placeholder="환전금액을 입력해주세요."
                  type="text"
                  name="money"
                  inputClassName=""
                  onChange={onChangeMoney}
                  value={(money == 0) ? "" : Number(money).toLocaleString()} /> */}

                <div className="d-flex input-group">
                  <div className="input-label input-label-mark">환전금액</div>
                  <div className="position-relative input-box">
                    <input type="text"
                      placeholder="환전금액을 입력해주세요."
                      name="money"
                      value={(Number(money) === 0) ? "" : Number(money).toLocaleString()}
                      onChange={onChangeMoney} />
                  </div>
                </div>
                <div className="d-flex input-group money-btn-group">
                  <div className="input-label"></div>
                  <div className="pad5t input-box">
                    {
                      moneyBtns.map((btn, index) => {
                        return (
                          <div className="money-btn" key={index} onClick={() => addMoney(btn.value)}>
                            { btn.name}
                          </div>
                        )
                      })
                    }
                  </div>
                </div>

                <div className="list d-flex justify-end">
                  <div className="reg-btn" onClick={onWithdraw} onDoubleClick={e => e.preventDefault}>환전신청</div>
                </div>
              </div>

              <div className="register-div" style={{ paddingRight: "0" }}>
                <div>
                  <table className="event-table">
                    <thead>
                      <tr>
                        <th className="no">번호</th>
                        <th className="">환전금액</th>
                        <th className="date-time">시간</th>
                        <th className="date-time">상태</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        !isEmpty(history) ? history.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td className="no">{curPage * 10 - 9 + index}</td>
                              <td className="" style={{ width: "" }}>{Number(item.amount).toLocaleString()}</td>
                              <td className="date-time">{dateFormation(item.created_at)}</td>
                              <td className={convertMoneyStatusClase(item.status)}>{convertMoneyStatus(item.status)}</td>
                            </tr>
                          )
                        })
                          :
                          <tr>
                            <td colSpan="4" style={{ height: "200px" }}>내역이 없습니다.</td>
                          </tr>
                      }
                    </tbody>
                  </table>
                </div>
                <div className="pad15t">
                  <Pagenation
                    page={curPage}
                    totalPage={totalPage}
                    getPage={getHistory} />
                </div>
              </div>
            </div>

          </div>


        </div>
      </div>
    </div>
  );
}

export default Withdraw;
