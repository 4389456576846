import { Link } from 'react-router-dom';

function TopBar() {
  return (
    <div className="gnbWrapper gnbSizeM">
      <div className="gnbBar">
        <h1 className="gnbLogo">
          <Link to="/">
            <img className="topLogo" src="../images/topgame.png" alt="TOPGAME" />
          </Link>
        </h1>
      </div>
    </div>
  );
}

export default TopBar;
