import { Fragment } from "react";

function EventDetail(props) {

  const { title, content, setActive } = props;

  return (
    <Fragment>
      <div className="swal2-container swal2-center swal2-backdrop-show" style={{ overflowY: "auto" }} >
        <div className="swal2-popup-back" onClick={() => setActive(-1)} onDoubleClick={e => e.preventDefault}></div>
        <div aria-labelledby="swal2-title" aria-describedby="swal2-html-container"
          className="swal2-popup swal2-modal swal2-icon-info swal2-show" tabIndex="-1" role="dialog" aria-live="assertive"
          aria-modal="true" style={{ display: "grid" }}>
          <div className="swal2-icon swal2-info swal2-icon-show" style={{ display: "flex" }}>
            <div className="swal2-icon-content" style={{ color: "#ffd30b" }}>i</div>
          </div>
          <h2 className="swal2-title" id="swal2-title" style={{ display: "block" }}>{title}</h2>
          <div className="swal2-actions text-center" style={{ display: "block" }} dangerouslySetInnerHTML={{ __html: content }}>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default EventDetail;
