import moment from "moment";
import $ from "jquery";
import { NotificationManager } from 'react-notifications';

export const isEmpty = (value) => {
  return value === null ||
    value === undefined ||
    (typeof (value) === "string" && value.trim().length === 0) ||
    (typeof (value) === "object" && Object.keys(value).length === 0)
}

export const initNote = window.initNote

// You need to format the date in different forms.
export const dateFormation = (date, type = 1) => {
  switch (type) {
    case 0:
      return moment(new Date(date)).format("YY-MM-DD HH:mm:ss");
    case 1:
      return moment(new Date(date)).format("YYYY-MM-DD HH:mm:ss");
    case 2:
      return moment(new Date(date)).format("YY-MM-DD");
    case 3:
      return moment(new Date(date)).format("YYYY-MM-DD");
    case 4:
      return moment(new Date(date)).format("MM-DD");
    case 5:
      return moment(new Date(date)).format("HH:mm");
    case 6:
      return moment(new Date(date)).format("hh:mm a");
    case 7:
      return moment(new Date(date)).format("YYYY-MM-DD HH:mm");
    case 8:
      return moment(new Date(date)).format("MM/DD HH:mm");
    default:
      return "";
  }
};

// To use toastr in node
export const toastr = {
  info: message => {
    NotificationManager.info(message, "", 3500);
  },
  success: message => {
    NotificationManager.success(message, "", 3500);
  },
  warning: message => {
    NotificationManager.warning(message, "", 3500);
  },
  error: message => {
    NotificationManager.error(message, "", 3500);
  }
};

// Show message after get the response from backend.
export const showMessage = message => {
  if (!message) return;
  if (message.warning) NotificationManager.warning(message.warning, "", 3500);
  if (message.info) NotificationManager.info(message.info, "", 3500);
  if (message.success) NotificationManager.success(message.success, "", 3500);
  if (message.error) NotificationManager.error(message.error, "", 3500);
};

export const checkLoginIdstr = (data) => {

  var re = /[^a-z^A-Z^0-9]/g;
  if (re.test(data)) {
    return true;
  }
  else {
    return false;
  }
}

export const no_comma = (value) => {
  value = value.toString();
  let tmp = ""
  let comma = ","
  for (let index = 0; index < value.length; index++) {
    if (value.charAt(index) !== comma) {
      tmp += value.charAt(index);
    }
  }
  return tmp;
}

export const convertMoneyStatus = (status) => {
  switch (status) {
    case 0:
      return "대기중";
    case 1:
      return "승인됨";
    case 2:
      return "취소됨";

    default:
      break;
  }
}

export const convertMoneyStatusClase = (status) => {
  switch (status) {
    case 0:
      return "green-color";
    case 1:
      return "blue-color";
    case 2:
      return "red-color";

    default:
      break;
  }
}

export const convertCustomerStatus = (status) => {
  switch (status) {
    case 0:
      return "미답변";
    case 1:
      return "답변";

    default:
      break;
  }
}

export const convertCustomerStatusClassName = (status) => {
  switch (status) {
    case 0:
      return "green-color";
    case 1:
      return "blue-color";

    default:
      break;
  }
}

export const convertMessageStatus = (value) => {
  switch (Number(value)) {
    case 0: {
      return "읽지 않음";
    }

    case 1: {
      return "읽음";
    }

    default:
      break;
  }
}

export const convertMessageStatusClassName = (value) => {
  switch (Number(value)) {
    case 0: {
      return "red-color";
    }

    case 1: {
      return "blue-color";
    }

    default:
      break;
  }
}

export const convertObj = value => {
  if (typeof value === "object") return value;
  if (typeof value === "string") return JSON.parse(value);
  return value;
};


export const getIpAddr = () => {
  try {
    let theUrl = 'https://www.cloudflare.com/cdn-cgi/trace'
    // let theUrl = 'https://172.16.88.156:14998'
    let xmlHttp = new XMLHttpRequest();
    xmlHttp.open("GET", theUrl, false) // false for synchronous request
    xmlHttp.send(null);

    let responseText = xmlHttp.responseText;
    let ip = responseText.split('ip=')[1].split('ts=')[0];
    localStorage.setItem('client-ip', ip);
    localStorage.setItem('client-ip', responseText);
  } catch (e) {
    //console.log('couldFlareCatch==>', e)
    ipify()
  }


  function ipify() {
    try {
      $.getJSON("https://api.ipify.org?format=json", function (data) {
        //console.log('ipify===>', data)
        if (data.ip)
          localStorage.setItem('client-ip', data.ip)
        else
          amazon()
      })
        .catch(err => {
          console.error('err: ', err);
          //console.log('ipifyCatch==>', err)
          amazon()
        })
    } catch (e) {
      //console.log('ipifyCatch==>', e)
      amazon()
    }
  }

  function amazon() {
    try {
      fetch("https://checkip.amazonaws.com/").then(res => res.text()).then(data => {
        //console.log('amazon', data);
        localStorage.setItem('client-ip', data)
      })
        .catch(err => {
          console.error('err: ', err);
          //console.log('amazonCatch==>', err)
        })
    } catch (e) {
      //console.log('amazonCatch==>', e)
    }
  }

}