import { Link } from 'react-router-dom';

function Gameplay() {

  return (
    <div className="btns">
      <p className="fl">
        <Link to="" className="info-btn"><span className="btxt">게임방법</span></Link>
        <Link to="" className="video-btn"><span className="btxt">동영상</span></Link>
      </p>
      <p className="fr">
        <Link to="" className="start-btn">
          <span className="btxt">게임시작</span>
        </Link>
      </p>
    </div>
  );
}

export default Gameplay;
