import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { requst } from '../api';
import axios from "axios";

// import { history } from '../helpers/history';
import { isEmpty, showMessage } from '../utils';
import { api_url } from '../utils/keys';
import setAuthToken from '../utils/setAuthToken';
import { userActions } from './user.slice';

// create slice

const name = 'topgame';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({ name, initialState, reducers });

// exports

export const topgameActions = { ...slice.actions, ...extraActions };
export const topgameReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable topgame to stay logged in
        isAuthenticated: false,
        data: {}
    }
}

function createReducers() {
    return {
        settopgameInfo
    };

    function settopgameInfo(state, action) {
        state.data = action.payload;
        state.isAuthenticated = !isEmpty(action.payload);
    }
}

function createExtraActions() {
    // const baseUrl = `${api_url}/topgames`;

    return {
        getTopGameList: getTopGameList()
    };

    function getTopGameList() {
        return createAsyncThunk(
            `${name}/gettopgameinfo`,
            async function (params, { dispatch }) {
                try {
                    const topgame = await axios.post(api_url + "/api/topgames/getmydata");
                    showMessage(topgame.message);
                    if(topgame.success){
                        setAuthToken(topgame.token);
                        localStorage.setItem("jwtToken", topgame.token)
                        dispatch(topgameActions.settopgameInfo(topgame.topgame));
                      }
                } catch (error) {
                    console.error(error);
                    if(error.response && (error.response.status === 401)){
                        dispatch(userActions.logout());
                      }
                }
            }
        );
    }
}