import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function PrivateRoute({ children }) {
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  if (!isAuthenticated) {
    return <Navigate to="/" replace />
  }
  return children
}
export default PrivateRoute;