import { useEffect, useState } from "react";
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { checkLoginIdstr, isEmpty, showMessage, toastr } from "../../utils";
import User from "../layout/User";
import Input from "./Input";
import { api_url } from "../../utils/keys";
import { history } from "../../helpers/history";
import { userActions } from "../../store";
import { request } from "../../api";

function Register() {
  const dispatch = useDispatch();
  const banks = useSelector(state => state.user.banks)
  const [inputs, setInputs] = useState({
    partner_code: '',
    loginid: '',
    loginpwd: '',
    loginpwd_confirm: '',
    username: '',
    bankname: '',
    accountnumber: '',
    mastername: '',
    withdrawPassword: '',
    withdrawPassword_confirm: '',
    telnum: '',
    // birthday: '',
    // gender: '1'
  });
  const {
    loginid,
    loginpwd,
    loginpwd_confirm,
    username,
    bankname,
    accountnumber,
    mastername,
    withdrawPassword,
    withdrawPassword_confirm,
    telnum,
    partner_code,
    // birthday,
    // gender
  } = inputs;

  const [error, setError] = useState({})

  const onChange = (e) => {
    const { value, name } = e.target; // 우선 e.target 에서 name 과 value 를 추출
    setInputs({
      ...inputs, // 기존의 input 객체를 복사한 뒤
      [name]: value // name 키를 가진 값을 value 로 설정
    });
  };

  const onReset = () => {
    setInputs({
      partner_code: '',
      loginid: '',
      loginpwd: '',
      loginpwd_confirm: '',
      username: '',
      bankname: '',
      accountnumber: '',
      mastername: '',
      withdrawPassword: '',
      withdrawPassword_confirm: '',
      telnum: '',
      // birthday: '',
      // gender: '1',
      error: {}
    })
  };

  const onCheckDuplication = () => {
    console.log("------------------");
    if (isEmpty(loginid)) {
      return toastr.warning('아이디를 입력해주세요.');
    }
    if (checkLoginIdstr(loginid)) {
      return toastr.warning("아이디에 특수문자를 포함할수 없습니다.");
    }
    request.post(api_url + "/api/users/checkloginid", { loginid })
      .then(res => {
        const { message } = res;
        showMessage(message);
        if (res.success) {
          setError({
            ...error,
            loginid: ""
          })
        }
        // else {
        //   const { message } = res.data;
        // }
      })
      .catch(err => {
        console.log(err);
        if (err.response === undefined) {
          toastr.warning('잠시후 다시 시도해주세요.');
          return;
        }
      });
  }

  const onRegister = e => {
    e.preventDefault();
    console.log("------------------");
    let params = {
      partner_code,
      loginid,
      loginpwd,
      loginpwd_confirm,
      username,
      bankname,
      accountnumber,
      mastername,
      withdrawPassword,
      withdrawPassword_confirm,
      telnum,
      // birthday,
      // gender,
    }

    let err = {};
    if (isEmpty(loginid)) err.loginid = '아이디를 입력해주세요.';
    if (checkLoginIdstr(loginid)) {
      err.loginid = "아이디에 특수문자를 포함할수 없습니다.";
    }
    if (isEmpty(username)) err.username = '닉네임을 입력해주세요.';
    // if (isEmpty(birthday)) {
    //   err.birthday = '생년월일을 입력해주세요.';
    // }
    // if (isEmpty(gender)) {
    //   err.gender = '성별을 입력해주세요.';
    // }
    if (isEmpty(loginpwd)) err.loginpwd = '비번을 입력해주세요.';
    if (isEmpty(loginpwd_confirm)) err.loginpwd_confirm = '비번확인을 입력해주세요.';
    if (isEmpty(telnum) || telnum.length < 11) err.telnum = '핸드폰번호를 입력해주세요.';
    if (isEmpty(partner_code)) err.partner_code = '가입코드를 입력해주세요.';
    if (isEmpty(withdrawPassword)) err.withdrawPassword = '환전비번을 입력해주세요.';
    if (isEmpty(withdrawPassword_confirm)) {
      err.withdrawPassword_confirm = '환전비번확인을 입력해주세요.';
      if (withdrawPassword !== withdrawPassword_confirm)
        err.withdrawPassword_confirm = '환전비번확인을 입력해주세요.';
    }
    if (isEmpty(bankname)) err.bankname = '은행명을 입력해주세요.';
    if (isEmpty(mastername)) err.mastername = '예금주를 입력해주세요.';
    if (isEmpty(accountnumber)) err.accountnumber = '계좌번호를 입력해주세요.';
    if (!isEmpty(err)) return setError(err);

    setError({});
    request.post(api_url + "/api/users/signup", params)
      .then(res => {
        const { success, errors, message } = res;
        showMessage(message);
        if (success) {
          onReset();
          history.navigate("/");
        }
        else {
          if (!isEmpty(errors)) {
            setError(errors);
          }
        }
      })
      .catch(err => {
        console.log(err)
        // if (err.response && err.response.status === 401) {
        //     props.logoutUser();
        // } else {
        toastr.warning('잠시후 다시 시도해주세요.');
        // }
      })
  }

  useEffect(() => {
    dispatch(userActions.getBankName());
  }, [])

  return (
    <div className="msec-core">
      <div className="register-border">
        <User nameKor="회원가입" name="" />

        <div className="abatar-item">

          <div className="slot register">
            <div className="list d-flex">
              <div className="register-div">

                <Input
                  labelName="아이디"
                  checkLoginId={true}
                  errorMsg={error.loginid}
                  placeholder="아이디를 입력해주세요."
                  type="text"
                  name="loginid"
                  inputClassName="loginid"
                  onChange={onChange}
                  value={loginid}
                  onCheckDuplication={onCheckDuplication} />

                <Input
                  labelName="닉네임"
                  checkLoginId={false}
                  errorMsg={error.username}
                  placeholder="닉네임을 입력해주세요."
                  type="text"
                  name="username"
                  inputClassName=""
                  onChange={onChange}
                  value={username} />

                <Input
                  labelName="비밀번호"
                  checkLoginId={false}
                  errorMsg={error.loginpwd}
                  placeholder="비밀번호를 입력해주세요."
                  type="password"
                  name="loginpwd"
                  inputClassName=""
                  onChange={onChange}
                  value={loginpwd} />

                <Input
                  labelName="비밀번호확인"
                  checkLoginId={false}
                  errorMsg={error.loginpwd_confirm}
                  placeholder="비밀번호확인을 입력해주세요."
                  type="password"
                  name="loginpwd_confirm"
                  inputClassName=""
                  onChange={onChange}
                  value={loginpwd_confirm} />

                <Input
                  labelName="연락처"
                  checkLoginId={false}
                  errorMsg={error.telnum}
                  placeholder="연락처를 입력해주세요."
                  type="text"
                  name="telnum"
                  inputClassName=""
                  onChange={onChange}
                  value={telnum} />

                <Input
                  labelName="가입코드"
                  checkLoginId={false}
                  errorMsg={error.partner_code}
                  placeholder="가입코드를 입력해주세요."
                  type="text"
                  name="partner_code"
                  inputClassName=""
                  onChange={onChange}
                  value={partner_code} />

              </div>
              <div className="register-div">

                {/* ------------ Select BankName Start --------- */}
                <div className="d-flex input-group">
                  <div className="input-label input-label-mark">은행명</div>
                  <div className="position-relative input-box">
                    <select name="bankname" value={bankname} onChange={onChange}>
                      <option value="">은행명을 입력해주세요.</option>
                      {
                        banks.map((bankList, index) => {
                          return (
                            <option value={bankList} key={index}>
                              {bankList}
                            </option>)
                        })
                      }
                    </select>
                  </div>
                </div>
                <div className="d-flex input-group">
                  <div className="input-label"></div>
                  {
                    !isEmpty(error.bankname) &&
                    <div className="cred pad5t">{error.bankname}</div>
                  }
                </div>
                {/* ----------- Select BankName End ----------- */}

                <Input
                  labelName="계좌번호"
                  checkLoginId={false}
                  errorMsg={error.accountnumber}
                  placeholder="계좌번호를 입력해주세요."
                  type="text"
                  name="accountnumber"
                  inputClassName=""
                  onChange={onChange}
                  value={accountnumber} />

                <Input
                  labelName="예금주"
                  checkLoginId={false}
                  errorMsg={error.mastername}
                  placeholder="예금주를 입력해주세요."
                  type="text"
                  name="mastername"
                  inputClassName=""
                  onChange={onChange}
                  value={mastername} />

                <Input
                  labelName="환전비밀번호"
                  checkLoginId={false}
                  errorMsg={error.withdrawPassword}
                  placeholder="환전비밀번호를 입력해주세요."
                  type="password"
                  name="withdrawPassword"
                  inputClassName=""
                  onChange={onChange}
                  value={withdrawPassword} />

                <Input
                  labelName="환전비번확인"
                  checkLoginId={false}
                  errorMsg={error.withdrawPassword_confirm}
                  placeholder="환전비번확인를 입력해주세요."
                  type="password"
                  name="withdrawPassword_confirm"
                  inputClassName=""
                  onChange={onChange}
                  value={withdrawPassword_confirm} />
              </div>
            </div>
            <div className="list d-flex justify-end top">
              <div className="reg-btn" onClick={onRegister}>가입하기</div>
            </div>
          </div>


        </div>
      </div>
    </div>
  );
}

export default Register;
