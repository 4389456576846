import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';

import User from '../../layout/User';
import { convertCustomerStatus, convertCustomerStatusClassName, dateFormation, isEmpty, showMessage, toastr } from '../../../utils';
import { moreActions, userActions } from '../../../store';
import Pagenation from '../../common/Pagenation';
import CustomerInput from './CustomerInput';
import { api_url } from '../../../utils/keys';

function Customer() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  const customers = useSelector(state => state.more.customers);
  const totalPage = useSelector(state => state.page.totalPage);
  const [active, setActive] = useState(-1);
  const [curPage, setCurPage] = useState(1);
  const [newQuestion, setNewQuestion] = useState(false);

  const getList = (page = 1) => {
    const params = {
      page: Number(page),
      perPage: 10,
      type: ""
    }
    if ((totalPage >= page) && (page > 0)) {
      setCurPage(page);
      dispatch(moreActions.getCustomers(params));
    }
  }

  const onNewQuestion = () => {
    setNewQuestion(!newQuestion);
  }

  const onQuestion = (params) => {
    axios.post(api_url + "/api/question/send", params)
      .then(res => {
        const data = res.data
        showMessage(res.data.message)
        if (data.success) {
          getList(1);
        }
      })
  }

  const onAccountQuestion = () => {
    const params = {
      title: "빠른계좌 문의합니다.",
      question: "빠른계좌 문의합니다."
    }
    onQuestion(params);
  }

  useEffect(() => {
    if (isAuthenticated) {
      getList(1);
    }
  }, [isAuthenticated]);

  return (
    <div className="msec-core">
      <div className="register-border">
        <User nameKor="고객센터" name="" />

        <div className="abatar-item">

          <div className="slot">

            <div className="list d-flex justify-end w-100" style={{ padding: "0 27px", position: "relative", overflow: "unset" }}>
              <div className="reg-btn question-btn" style={{ marginRight: "0" }} onClick={onAccountQuestion} onDoubleClick={e => e.preventDefault}>빠른 계좌문의</div>
              <div className="reg-btn question-btn" style={{ marginRight: "0" }} onClick={onNewQuestion} onDoubleClick={e => e.preventDefault}>문의하기</div>
            </div>

            <div className="list">

              <table className="event-table">
                <thead>
                  <tr>
                    <th className="no">번호</th>
                    <th className="">제목</th>
                    <th className="date-time">시간</th>
                    <th className="">상태</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    !isEmpty(customers) ? customers.map((item, index) => {
                      return (
                        <tr onClick={() => setActive(index)} onDoubleClick={e => e.preventDefault} key={index}>
                          <td className="no">{curPage * 10 - 9 + index}</td>
                          <td className="" style={{ width: "calc(100% - 170px)" }}>
                            <div className="content">{item.title}</div>
                          </td>
                          <td className="date-time">{dateFormation(item.created_at, 7)}</td>
                          <td className={convertCustomerStatusClassName(item.status) + " text-shadow"}>{convertCustomerStatus(item.status)}</td>
                        </tr>
                      )
                    })
                      :
                      <tr>
                        <td colSpan="4" style={{ height: "300px" }}>내역이 없습니다.</td>
                      </tr>
                  }
                </tbody>
              </table>
            </div>
            <Pagenation
              page={curPage}
              totalPage={totalPage}
              getPage={getList} />
          </div>

          {
            ((active !== -1) || newQuestion) &&
            <CustomerInput
              title={!newQuestion && customers[active].title}
              content={!newQuestion && customers[active].question}
              answer={!newQuestion && customers[active].answer}
              setActive={setActive}
              newQuestion={newQuestion}
              onNewQuestion={onNewQuestion}
              onQuestion={onQuestion}
            />
          }

        </div>
      </div>
    </div>
  );
}

export default Customer;
