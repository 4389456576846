import { Fragment } from 'react';
import { Outlet } from 'react-router-dom';

import TopBar from "../component/layout/TopBar";
import Barnner from "../component/common/Barnner"
import RightBox from '../component/layout/RightBox';
import LeftBox from '../component/layout/LeftBox';

function Main() {
  return (
    <Fragment>
      <TopBar/>
      <div id="container-wrap" className="div-wrap">
        <div className="bx-wrapper" style={{maxWidth: "100%"}}>
          <Barnner/>
        </div>
        <div id="contents" className="div-cont mcontainer">
          <LeftBox/>
          <Outlet/>
          <RightBox/>
        </div>
      </div>
    </Fragment>
  );
}

export default Main;
