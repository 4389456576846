import { useEffect, useState } from "react";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import { convertMoneyStatus, convertMoneyStatusClase, dateFormation, isEmpty, no_comma, showMessage, toastr } from "../../../utils";
import { api_url } from "../../../utils/keys";
import User from "../../layout/User";
import { moreActions } from "../../../store";
import Pagenation from "../../common/Pagenation";

const rule = `
<p class="color-gray" style="display: flex;">
    <span style="font-size: 14px;">
      토스 (핑크, 리브 등), 각종페이 (카카오, 삼성페이), 증권계좌, CD기 등을
      통한 충전은 처리 불가하며 타 명의를 통한 3자 충전의 경우에도 충전처리가 불가하며 반환처리 됩니다.
    </span>
</p>
<p class="color-gray" style="display: flex;">
    <span style="font-size: 14px;">
      충전신청 가능 최소 금액은 10,000원 이상 10,000원 단위로만 가능 합니다.</span>
</p>
<p class="color-gray" style="display: flex;">
    <span style="font-size: 14px;">
      100원 혹은 1000원 단위로는 충전처리가 불가능합니다.
    </span>
</p>
<p class="color-gray" style="display: flex;">
    <span style="font-size: 14px;">
      충전시 등록된 계좌로만 충전이 가능합니다.
    </span>
</p>
<p class="color-gray" style="display: flex;">
    <span style="font-size: 14px;">
      현재 금융감독원 모니터링 강화로 고액충전시에는 꼭 600만원 이하로 충전해주시길 바랍니다.
    </span>
</p>
<p class="color-gray" style="display: flex;">
    <span style="font-size: 14px;">
      예) 1000만 충전시
      <br> 
      600만 / 400만 으로 나눠서 충전
    </span>
</p>`

const moneyBtns = [
  {
    name: "10,000",
    value: 10000
  },
  {
    name: "30,000",
    value: 30000
  },
  {
    name: "50,000",
    value: 50000
  },
  {
    name: "100,000",
    value: 100000
  },
  {
    name: "300,000",
    value: 300000
  },
  {
    name: "500,000",
    value: 500000
  },
  {
    name: "1,000,000",
    value: 1000000
  },
  {
    name: "초기화",
    value: 0
  },
]
function Deposit() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  const history = useSelector(state => state.more.depositList);
  const totalPage = useSelector(state => state.page.totalPage);
  const [curPage, setCurPage] = useState(1);

  const [inputs, setInputs] = useState({
    withdrawPassword: '',
    money: 0,
  });

  const {
    withdrawPassword,
    money
  } = inputs;

  const [error, setError] = useState({});

  const onChangeMoney = (e) => {
    const { value, name } = e.target; // 우선 e.target 에서 name 과 value 를 추출
    if (isNaN(Number(no_comma(value)))) {
      setInputs({
        ...inputs, // 기존의 input 객체를 복사한 뒤
        [name]: 0 // name 키를 가진 값을 value 로 설정
      });
    }
    else {
      setInputs({
        ...inputs, // 기존의 input 객체를 복사한 뒤
        [name]: no_comma(value) // name 키를 가진 값을 value 로 설정
      });
    }
  };

  const onReset = () => {
    setInputs({
      withdrawPassword: '',
      money: 0
    })
  };

  const getHistory = (page = 1) => {
    if ((totalPage >= page) && (page > 0)) {
      setCurPage(page);
      dispatch(moreActions.getDepositList({
        typeStr: "deposit",
        page: page,
        perPage: 10
      }));
    }
  }

  const onDeposit = e => {
    e.preventDefault();

    const params = {
      amount: money,
      withdrawPassword,
      typeStr: "deposit"
    }

    if (isEmpty(money) || (Number(money) === 0)) {
      return toastr.warning('머니를 입력해주세요.');
    }

    axios.post(api_url + "/api/money/exchange", params)
      .then(res => {
        const { success, errors, message } = res.data;
        showMessage(message);
        if (success) {
          onReset();
          getHistory();
        }
        else {
          console.log('error: ', error);
          setError(errors);
        }
      })
      .catch(err => {
        console.log(err)
        // if (err.response && err.response.status === 401) {
        //     props.logoutUser();
        // } else {
        toastr.warning('잠시후 다시 시도해주세요.');
        // }
      })
  }

  const addMoney = (value) => {
    if (value === 0) {
      setInputs({
        ...inputs,
        money: 0
      });
    }
    else {
      setInputs({
        ...inputs,
        money: Number(money) + value
      });
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      getHistory(1);
    }
  }, [isAuthenticated])

  return (
    <div className="msec-core">
      <div className="register-border">
        <User nameKor="충전" name="" />

        <div className="abatar-item">
          <div className="slot register my-page">
            <div className="list d-flex flex-wrap">

              <div className="register-div">

                <div className="w-100 rule-div pad15f" dangerouslySetInnerHTML={{ __html: rule }}>
                </div>

                {/* <Input
                  labelName="충전금액"
                  checkLoginId={false}
                  errorMsg={""}
                  placeholder="충전금액을 입력해주세요."
                  type="text"
                  name="money"
                  inputClassName=""
                  onChange={onChangeMoney}
                  value={(money == 0) ? "" : Number(money).toLocaleString()} /> */}


                <div className="d-flex input-group">
                  <div className="input-label input-label-mark">충전금액</div>
                  <div className="position-relative input-box">
                    <input type="text"
                      placeholder="충전금액을 입력해주세요."
                      name="money"
                      value={(Number(money) === 0) ? "" : Number(money).toLocaleString()}
                      onChange={onChangeMoney} />
                  </div>
                </div>
                <div className="d-flex input-group money-btn-group">
                  <div className="input-label"></div>
                  <div className="pad5t input-box">
                    {
                      moneyBtns.map((btn, index) => {
                        return (
                          <div className="money-btn" key={index} onClick={() => addMoney(btn.value)}>
                            { btn.name}
                          </div>
                        )
                      })
                    }
                  </div>
                </div>

                <div className="list d-flex justify-end pad10t">
                  <div className="reg-btn" onClick={onDeposit} onDoubleClick={e => e.preventDefault}>충전신청</div>
                </div>
              </div>

              <div className="register-div" style={{ paddingRight: "0" }}>
                <div>
                  <table className="event-table">
                    <thead>
                      <tr>
                        <th className="no">번호</th>
                        <th className="">충전금액</th>
                        <th className="date-time">시간</th>
                        <th className="date-time">상태</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        !isEmpty(history) ? history.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td className="no">{curPage * 10 - 9 + index}</td>
                              <td className="" style={{ width: "" }}>{Number(item.amount).toLocaleString()}</td>
                              <td className="date-time">{dateFormation(item.created_at)}</td>
                              <td className={convertMoneyStatusClase(item.status)}>{convertMoneyStatus(item.status)}</td>
                            </tr>
                          )
                        })
                          :
                          <tr>
                            <td colSpan="4" style={{ height: "200px" }}>내역이 없습니다.</td>
                          </tr>
                      }
                    </tbody>
                  </table>
                </div>
                <div className="pad15t">
                  <Pagenation
                    page={curPage}
                    totalPage={totalPage}
                    getPage={getHistory} />
                </div>
              </div>
            </div>

          </div>


        </div>
      </div>
    </div>
  );
}

export default Deposit;
