import { useSelector } from 'react-redux';

import Login from './Login';
import UserInfo from './UserInfo';
import UserTitle from './UserTitle';

function User(props) {

  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  
  const { nameKor, name } = props;

  return (
    <div className="login">
      <UserTitle nameKor={nameKor} name={name}/>

      {
        isAuthenticated ?
          <UserInfo />
          :
          <Login />
      }
    </div>
  );
}

export default User;
