import { createSlice } from '@reduxjs/toolkit';

// create slice

const name = 'page';
const initialState = createInitialState();
const reducers = createReducers();
// const extraActions = createExtraActions();
const slice = createSlice({ name, initialState, reducers });

// exports

export const pageActions = { ...slice.actions };
export const pageReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable page to stay logged in
        curPage: 1,
        perPage: 10,
        totalPage: 1
    }
}

function createReducers() {
    return {
        setCurPage,
        setTotalPage,
    };

    function setCurPage(state, action) {
        state.page = action.payload;
    }

    function setTotalPage(state, action) {
        state.totalPage = action.payload;
    }
}