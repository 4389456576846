import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';

import User from '../../layout/User';
import { api_url } from '../../../utils/keys';
import { dateFormation, isEmpty, toastr } from '../../../utils';
import { moreActions, userActions } from '../../../store';
import EventDetail from '../EventDetail';
import Pagenation from '../../common/Pagenation';

function Event() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  const events = useSelector(state => state.more.events);
  const totalPage = useSelector(state => state.page.totalPage);
  const [active, setActive] = useState(-1);
  const [curPage, setCurPage] = useState(1);

  const getList = (page) => {
    const params = {
      page: Number(page),
      perPage: 10,
      type: ""
    }
    if ((totalPage >= page) && (page > 0)) {
      setCurPage(page);
      dispatch(moreActions.getEvents(params));
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      getList(1);
    }
  }, [isAuthenticated])

  return (
    <div className="msec-core">

      <User nameKor="이벤트" name="EVENT" />



      <div className="abatar-item">

        <div className="slot">
          <div className="list">
            <table className="event-table">
              <thead>
                <tr>
                  <th className="no">번호</th>
                  <th className="">제목</th>
                  <th className="date-time">시간</th>
                </tr>
              </thead>
              <tbody>
                {
                  !isEmpty(events) ? events.map((item, index) => {
                    return (
                      <tr onClick={() => setActive(index)} onDoubleClick={e => e.preventDefault} key={index}>
                        <td className="no">{curPage * 10 - 9 + index}</td>
                        <td className="" style={{ width: "calc(100% - 170px)" }}>
                          <div className="content">{item.title}</div>
                        </td>
                        <td className="date-time">{dateFormation(item.created_at, 7)}</td>
                      </tr>
                    )
                  })
                    :
                    <tr>
                      <td colSpan="3" style={{ height: "300px" }}>내역이 없습니다.</td>
                    </tr>
                }
              </tbody>
            </table>
          </div>
          <Pagenation
            page={curPage}
            totalPage={totalPage}
            getPage={getList} />
        </div>
        {(active !== -1) &&
          <EventDetail
            title={events[active].title}
            content={events[active].content}
            setActive={setActive}
          />}

      </div>
    </div>
  );
}

export default Event;
