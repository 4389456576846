import { Link, NavLink } from 'react-router-dom';
import { userActions } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { Fragment, useState } from 'react';

const menus = [
  {
    name: "게임",
    path: "/",
  },
  {
    name: "이벤트",
    path: "/event",
  },
  {
    name: "공지사항",
    path: "/notice",
  },
  {
    name: "쪽지",
    path: "/message",
  },
  {
    name: "포인트전환",
    path: "/pointexchange",
  },
  {
    name: "고객센터",
    path: "/cusotmer",
  },
  {
    name: "마이페이지",
    path: "/mypage",
  },
  // {
  //   name: "추천인",
  //   path: "/",
  // },
]

function UserInfo() {

  const dispatch = useDispatch();
  const user = useSelector(state => state.user.data);
  const [popup, setPopup] = useState(false);

  const onPopup = () => {
    setPopup(!popup);
  }

  const onLogout = () => {
    dispatch(userActions.logout());
    onPopup();
  }
  return (
    <Fragment>
      <div className="fr- d-flex">
        <div className="inputs" style={{ float: "unset" }}>
          <span className="topgame-username">{user.username} </span>
          {/* <div className="btn" alt="동일 명의 ID에 실버를 이동할 수 있습니다"><span>실버이동</span></div> */}
        </div>
        <div className="inputs font-size-16" >

          <div className="mg15r">
            <Link to="/deposit" className="btn"><span>충전</span></Link>
            <Link to="/withdraw" className="btn"><span>환전</span></Link>
          </div>

          <div className="topgame-silver-div">
            <span className="topgame-silver">보유머니: <span className="cyellow">{isNaN(user.money) ? 0 : Number(user.money).toLocaleString()}&nbsp;</span>원</span>
            <span className="topgame-silver">보유포인트: <span className="cyellow">{isNaN(user.rolling) ? 0 : Number(user.rolling).toLocaleString()}&nbsp;</span>P</span>
          </div>
          <div className="btn" onClick={onPopup} onDoubleClick={e => e.preventDefault}><span>로그아웃</span></div>
          {/* <span className="topgame-glod"><img src="../images/main/icon_gold.png" alt="보유골드머니" />0&nbsp;골드</span>
        <span className="topgame-silver"><img src="../images/main/icon_silver.png" alt="보유실버머니" />{isNaN(user.money) ? 0 : Number(user.money).toLocaleString()}&nbsp;실버</span>
        <span className="topgame-silver"><img src="../images/main/icon_ruby.png" alt="보유루비머니" />0&nbsp;루비</span> */}


          {/* <span className="topgame-cash">
          <img src="../images/main/icon_cash.png" alt="보유캐쉬" />
          0&nbsp;캐쉬
        </span> */}

          {/* <div>
            <Link to="/deposit" className="btn"><span>충전</span></Link>
            <Link to="/withdraw" className="btn"><span>환전</span></Link>
          </div> */}
        </div>
      </div>
      <div className="btns w-100 ">
        {
          menus.map((menu, index) => {
            return (
              <NavLink
                to={menu.path}
                className={({ isActive }) => "psfind-btn " + (isActive ? "cyellow" : "")}
                key={index}>
                <span>
                  <strong>
                    {menu.name}
                  </strong>
                </span>
              </NavLink>
            )
          })
        }
        {/* <NavLink to="/pointexchange" className={({ isActive }) => "joui-btn " + (isActive ? "cyellow" : "")}><span><strong>포인트전환</strong></span></NavLink>
        <NavLink to="/mypage" className={({ isActive }) => "joui-btn " + (isActive ? "cyellow" : "")}><span><strong>마이페이지</strong></span></NavLink>
        <NavLink to="/" className={({ isActive }) => "psfind-btn " + (isActive ? "cyellow" : "")}><span>추천인</span></NavLink> */}
      </div>

      {
        popup &&
        <div className="swal2-container swal2-center swal2-backdrop-show" style={{ overflowY: "auto" }} >
          <div className="swal2-popup-back" onClick={onPopup} onDoubleClick={e => e.preventDefault}></div>
          <div aria-labelledby="swal2-title" aria-describedby="swal2-html-container"
            className="swal2-popup swal2-modal swal2-icon-info swal2-show" tabIndex="-1" role="dialog" aria-live="assertive"
            aria-modal="true" style={{ display: "grid", padding: "20px 24px", width: "480px",borderRadius: "5px" }}>
            <div className="swal2-icon swal2-info swal2-icon-show" style={{ display: "flex" }}>
              <div className="swal2-icon-content" style={{ color: "#ffd30b" }}>?</div>
            </div>
            <div className=" d-flex w-100 justify-center pad20f pad40t betting_modal_text">
              <span className="popup-btn mg15r" onClick={onLogout} onDoubleClick={e => e.preventDefault}>
                네
            </span>
              <span className="popup-btn" onClick={onPopup} onDoubleClick={e => e.preventDefault}>
                아니오
            </span>
            </div>
          </div>
        </div>
      }
    </Fragment>
  );
}

export default UserInfo;
