import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';

import User from '../../layout/User';
import { api_url } from '../../../utils/keys';
import { convertMessageStatus, convertMessageStatusClassName, dateFormation, isEmpty, toastr } from '../../../utils';
import { moreActions, userActions } from '../../../store';
import EventDetail from '../EventDetail';
import Pagenation from '../../common/Pagenation';
import { request } from '../../../api';

function Message() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  const messages = useSelector(state => state.more.messages);
  const totalPage = useSelector(state => state.page.totalPage);
  const [active, setActive] = useState(-1);
  const [curPage, setCurPage] = useState(1);

  const getList = (page) => {
    const params = {
      page: Number(page),
      perPage: 10,
      type: ""
    }
    if ((totalPage >= page) && (page > 0)) {
      setCurPage(page);
      dispatch(moreActions.getMessages(params));
    }
  }

  const onMessage = (id, index) => {
    setActive(index);
    const params = {
      id: id
    }
    request.post("/api/notes/getContent", params)
      .then(res => {
        // toastr.success(res.messages);
        getList(curPage);
      })
  }
  useEffect(() => {
    if (isAuthenticated) {
      getList(1);
    }
  }, [isAuthenticated]);

  return (
    <div className="msec-core">

      <User nameKor="쪽지" name="" />



      <div className="abatar-item">

        <div className="slot">
          <div className="list">
            <table className="event-table">
              <thead>
                <tr>
                  <th className="no">번호</th>
                  <th className="">제목</th>
                  <th className="date-time">상태</th>
                  <th className="date-time">시간</th>
                </tr>
              </thead>
              <tbody>
                {
                  !isEmpty(messages) ? messages.map((item, index) => {
                    return (
                      <tr onClick={() => onMessage(item.id, index)} onDoubleClick={e => e.preventDefault} key={index}>
                        <td className="no">{curPage * 10 - 9 + index}</td>
                        <td className="" style={{ width: "calc(100% - 170px)" }}>
                          <div className="content">{item.title}</div>
                        </td>
                        <td className={`date-time ${convertMessageStatusClassName(item.is_read)}`}>{convertMessageStatus(item.is_read)}</td>
                        <td className="date-time">{dateFormation(item.created_at, 7)}</td>
                      </tr>
                    )
                  })
                    :
                    <tr>
                      <td colSpan="4" style={{ height: "300px" }}>내역이 없습니다.</td>
                    </tr>
                }
              </tbody>
            </table>
          </div>
          <Pagenation
            page={curPage}
            totalPage={totalPage}
            getPage={getList} />
        </div>
        {(active !== -1) &&
          <EventDetail
            title={messages[active].title}
            content={messages[active].content}
            setActive={setActive}
          />}

      </div>
    </div>
  );
}

export default Message;
