import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { userActions } from '../../store/user.slice';
import { checkLoginIdstr, isEmpty, toastr } from '../../utils';
import { Fragment } from 'react';

function Login() {

  const dispatch = useDispatch();

  const [inputs, setInputs] = useState({
    loginid: "",
    loginpwd: ""
  });

  const { loginid, loginpwd } = inputs;

  const onChange = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value
    });
  }

  const onLogin = (e) => {
    if (isEmpty(loginid)) {
      return toastr.warning("아이디를 입력해주세요.");
    }
    if (checkLoginIdstr(loginid)) {
      return toastr.warning("아이디에 특수문자를 포함할수 없습니다.");
    }
    if (isEmpty(loginpwd)) {
      return toastr.warning("비밀번호를 입력해주세요.");
    }

    const params = {
      loginid,
      loginpwd,
      ipADD: localStorage.getItem('client-ip') 
    }
    dispatch(userActions.login(params));
  }

  const onEnter = (e) => {
    if (e.key === 'Enter') {
      onLogin();
    }
  }

  return (
    <Fragment>
      <div className="fr- d-flex">
        {/* <div className="checks">
          </div> */}
        <div className="inputs">
          <div onKeyDown={onEnter}>
            <input type="text"
              placeholder="아이디"
              name="loginid"
              value={loginid}
              onChange={onChange} />
            <input type="password"
              placeholder="비밀번호"
              name="loginpwd"
              value={loginpwd}
              onChange={onChange} />

            <div className="btn" onClick={onLogin} onDoubleClick={e => e.preventDefault}>
              <span>로그인</span>
            </div>
          </div>
        </div>
        <div className="btns w-100- align-items-center ml-1" style={{ marginTop: 5, marginLeft: 10 }}>
          <Link to="/register" className="psfind-btn"><span>회원가입</span></Link>
          {/* <Link to="" className="idfind-btn"><span>아이디 찾기</span></Link>
          <Link to="" className="psfind-btn"><span>비밀번호 찾기</span></Link> */}
        </div>
      </div>
    </Fragment>
  );
}

export default Login;
