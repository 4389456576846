
function SlotItem(props) {
  const { name, image, GameLaunch } = props;
  return (
    <li className=" slot-item" onClick={() => GameLaunch(name)} onDoubleClick={e => e.preventDefault}>
      <div className="img">
        <div>
          <img src={image} alt={name}/>
        </div>
      </div>
      <div className="btxt text-center" >
        <div>{name}</div>
      </div> 
    </li> 
  );
}

export default SlotItem;
