import axios from "axios";
import { store, userActions } from "../store";
import { isEmpty, showMessage, toastr } from "../utils";
import { api_url } from "../utils/keys";

let reqUrl = "";
let isSending = false;



export const request = {
  get: (url, params) => {
    return new Promise(resolve => {
      if (!isSending || (url !== reqUrl)) {
        reqUrl = url;
        isSending = true;
        axios.get(url, params)
          .then(res => {
            isSending = false;
            const data = res.data;
            if (data.success) {
              resolve(res.data);
            }
            else {
              if (!isEmpty(data.message)) {
                toastr.warning(data.message);
              }
              showMessage(data.message)
            }
          })
          .catch(err => {
            isSending = false;
            console.log("err", err);
            if (err.response && (err.response.status === 401)) {
              store.dispatch(userActions.logout());
            }
            return toastr.warning("잠시후 다시 시도해주세요.");
          })
      }
    })
  },
  post: (url, params) => {
    return new Promise(resolve => {
      if (!isSending || (url !== reqUrl)) {
        reqUrl = url;
        isSending = true;
        axios.post(url, params)
          .then(res => {
            isSending = false;
            const data = res.data;
            if (data.success) {
              resolve(res.data);
            }
            else {
              if (!isEmpty(data.message)) {
                toastr.warning(data.message);
              }
              showMessage(data.message)
            }
          })
          .catch(err => {
            isSending = false;
            console.log("err", err);
            if (err.response && (err.response.status === 401)) {
              store.dispatch(userActions.logout());
            }
            return toastr.warning("잠시후 다시 시도해주세요.");
          })
      }
    })
  },
  put: (url, params) => {
    return new Promise(resolve => {
      if (!isSending || (url !== reqUrl)) {
        reqUrl = url;
        isSending = true;
        axios.put(url, params)
          .then(res => {
            isSending = false;
            const data = res.data;
            if (data.success) {
              resolve(res.data);
            }
            else {
              if (!isEmpty(data.message)) {
                toastr.warning(data.message);
              }
              showMessage(data.message)
            }
          })
          .catch(err => {
            isSending = false;
            console.log("err", err);
          })
      }
    })
  }
}