
function UserTitle(props) {

  const { nameKor, name } = props;
  return (
    <div className="fl-">
      <div className="tit">
        {nameKor}
        {/* <span>{name}</span> */}
      </div>
    </div>
  );
}

export default UserTitle;
