import { convertObj, toastr } from ".";
import { history } from "../helpers/history";
import { moreActions, store, userActions } from "../store";
import { ws_api_url } from "./keys"

export const initSocket = () => {
  window.socket = new WebSocket(ws_api_url);
  const socket = window.socket;

  socket.onopen = event => {
    while (socket.readyState !== 2 && socket.readyState !== 3) {
      if (socket.readyState === 1) {
        // console.log("------------Socket Connected---------------");
        // heartBeat();
        socket.send(
          JSON.stringify({
            MessageType: "PublicLogin",
            token: localStorage.jwtToken
          })
        );
        if (localStorage.jwtToken) {
          store.dispatch(userActions.getUserInfo());
        }
        break;
      }
    }
  }

  socket.onerror = event => {
    // console.log("error", event);
  }

  socket.onclose = event => {
    // console.log("-----------Socket Closed--------------");
    store.dispatch(userActions.logout());
    setTimeout(() => {
      initSocket();
    }, 10000);
  }

  socket.onmessage = (event) => {
    const data = convertObj(event.data);
    const messageType = data.MessageType;
    dataProcess(messageType, data.Data);
    // history
    // console.log('history: ', data.data.MessageType, data);
  }
}

const params = {
  page: 1,
  perPage: 10,
  type: ""
}

export const dataProcess = (messageType, data) => {
  switch (messageType) {
    case "userinfolist":
      userInfoProcess(data);
      break;

    case "messageList":
      messageProcess(data);
      break;

    case "questionList":
      customerProcess(data);
      break;

    case "noticeList":
      noticeProcess(data);
      break;

    case "charge_allow":
      depositProcess(data);
      break;

    case "withdraw_allow":
      withdrawProcess(data);
      break;

    case "Logout":
      logOut(data);
      break;

    default:
      break;
  }
}

export const userInfoProcess = (data) => {
  if (data.success) {
    const user = store.getState().user.data;
    if (user.loginid === data.data.loginid) {
      store.dispatch(userActions.setUserInfo(data.data));
    }
  }
}

export const messageProcess = (data) => {
  if (data.success) {
    toastr.info(data.message);
    history.navigate("/message");
    if (history.location.pathname === "/message") {
      store.dispatch(moreActions.getMessages(params));
    }
  }
}

export const customerProcess = (data) => {
  if (data.success) {
    toastr.info(data.message);
    if (history.location.pathname === "/cusotmer") {
      store.dispatch(moreActions.getCustomers(params));
    }
  }
}

export const noticeProcess = (data) => {
  if (data.success) {
    toastr.info(data.message);
    if (history.location.pathname === "/notice") {
      store.dispatch(moreActions.getNotices(params));
    }
  }
}

export const depositProcess = (data) => {
  if (data.success) {
    if (data.type === 1) {
      toastr.info(data.message);
    }
    if (data.type === 2) {
      toastr.error(data.message);
    }
    store.dispatch(userActions.getUserInfo());
    if (history.location.pathname === "/deposit") {
      store.dispatch(moreActions.getDepositList({
        ...params,
        typeStr: "deposit"
      }));
    }
  }
}

export const withdrawProcess = (data) => {
  console.log('data: ', data);
  if (data.success) {
    if (data.type === 1) {
      toastr.info(data.message);
    }
    if (data.type === 2) {
      toastr.error(data.message);
    }
    store.dispatch(userActions.getUserInfo());
    if (history.location.pathname === "/withdraw") {
      store.dispatch(moreActions.getWithdrawList({
        ...params,
        typeStr: "withdraw"
      }));
    }
  }
}

export const logOut = (data) => {
  console.log('data: ', data);
  store.dispatch(userActions.logout());
  if (data.success) {
  }
}