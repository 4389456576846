import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import { requst } from '../api';
import axios from "axios";
import { history } from '../helpers/history';
// import { store } from '.';

// import { history } from '../helpers/history';
import { isEmpty, showMessage, toastr } from '../utils';
import { api_url } from '../utils/keys';
import setAuthToken from '../utils/setAuthToken';

// create slice

const name = 'auth';
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const slice = createSlice({ name, initialState, reducers });

// exports

export const userActions = { ...slice.actions, ...extraActions };
export const userReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        isAuthenticated: false,
        data: {},
        banks: [
            "국민은행",
            "제국은행",
            "우리은행",
            "내은행",
            "새마을금고",
            "신한은행"
        ]
    }
}

function createReducers() {
    return {
        setUserInfo,
        setBanks,
    };

    function setUserInfo(state, action) {
        state.data = action.payload;
        state.isAuthenticated = !isEmpty(action.payload);
    }

    function setBanks(state, action) {
        state.banks = action.payload;
    }
}

function createExtraActions() {
    // const baseUrl = `${api_url}/users`;

    return {
        login: login(),
        logout: logout(),
        getUserInfo: getUserInfo(),
        getBankName: getBankName()
    };

    function login() {
        return createAsyncThunk(
            `${name}/login`,
            async function (params, { dispatch }) {
                axios.post(api_url + "/api/users/login", params)
                    .then(res => {
                        showMessage(res.data.message);

                        if (res.data.success) {
                            setAuthToken(res.data.token);
                            localStorage.setItem("jwtToken", res.data.token)
                            dispatch(userActions.setUserInfo(res.data.user));
                            history.navigate("/");
                            if (window.socket.readyState === 1) {
                                window.socket.send(JSON.stringify({ MessageType: 'PublicLogin', token: res.data.token }));
                            }
                        }
                    }).catch(error => {
                        console.error(error);
                        if (error.response && (error.response.status === 401)) {
                            return dispatch(userActions.logout());
                        }
                        else {
                            toastr.warning("잠시후 다시 시도해주세요.");
                        }
                    })
            }
        );
    }


    function getUserInfo() {
        return createAsyncThunk(
            `${name}/getuserinfo`,
            async function (params, { dispatch }) {
                try {
                    const res = await axios.post(api_url + "/api/users/getmydata");
                    const data = res.data
                    showMessage(data.message);
                    if (data.success) {
                        dispatch(userActions.setUserInfo(data.user));
                    }
                } catch (error) {
                    console.error(error);
                    if (error.response && (error.response.status === 401)) {
                        return dispatch(userActions.logout());
                    }
                    else {
                        toastr.warning("잠시후 다시 시도해주세요.");
                    }
                }
            }
        );
    }


    function getBankName() {
        return createAsyncThunk(
            `${name}/getbankname`,
            async function (params, { dispatch }) {
                try {
                    const res = await axios.post(api_url + "/api/users/getbank");
                    const data = res.data;
                    console.log('data: ', data);
                    if (data.success) {
                        dispatch(userActions.setBanks(data.banklist));
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        );
    }

    function logout() {
        return createAsyncThunk(
            `${name}/logout`,
            async function (arg, { getState, dispatch }) {
                if (getState().user.isAuthenticated) {
                    toastr.warning("로그아웃 되였습니다.");
                    try {
                        await axios.post(api_url + "/api/users/logout", { token: "" 
                    });
                    } catch (error) {
                        console.error(error);
                    }
                }


                localStorage.removeItem("jwtToken")
                dispatch(userActions.setUserInfo({}));
                setAuthToken(false);
                // history.navigate('/account/login');
            }
        );
    }
}