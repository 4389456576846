import { Fragment, useCallback, useEffect } from 'react';
import { Carousel } from 'rsuite';
import 'rsuite/dist/rsuite.css';
import { initNote } from '../../utils';


function TopBar() {

  // useEffect( useCallback(() => {
  //   // initNote(); 
  // }, []), []);

  return (
    <Fragment>
    <Carousel autoplay shape="bar" className="custom-slider">
      <img src="../images/Main/main-vis001.jpg" className="w-100" alt=""/>
      <img src="../images/Main/main-vis002.jpg" className="w-100" alt=""/>
    </Carousel>
    {/* <div id="demo" className="carousel slide" data-ride="carousel">
      <ul className="carousel-indicators">
        <li data-target="#demo" data-slide-to="0" className="active"></li>
        <li data-target="#demo" data-slide-to="1"></li>
      </ul>

      <div className="carousel-inner">
        <div className="carousel-item active">
          <img src="../images/Main/main-vis001.jpg" alt="Los Angeles" />
        </div>
        <div className="carousel-item">
          <img src="../images/Main/main-vis002.jpg" alt="Chicago" />
        </div>
      </div>

      <a className="carousel-control-prev" href="#demo" data-slide="prev">
        <span className="carousel-control-prev-icon"></span>
      </a>
      <a className="carousel-control-next" href="#demo" data-slide="next">
        <span className="carousel-control-next-icon"></span>
      </a>
    </div> */}
    {/* <div class="summernote"></div>
    <button onClick={initNote}>afad</button> */}
    </Fragment>
  );
}

export default TopBar;
