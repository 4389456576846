
function TopList(props) {

  const { title, list, style } = props
  return (
    <div className="rank" style={style}>
      <div className="tit">
        <p className="btxt">{title}<span>top</span></p>
        <p className="selects">
        </p>
      </div>
      <div className="list">
        <ul>
          {
            list.map((item, index) => {
              console.log('item: ', item);
              return <li key={index}></li>
            })
          }
        </ul>
      </div>
    </div>
  );
}

export default TopList;
