import { Link } from 'react-router-dom';

function LeftBox() {
  return (
    <div className="msec-left">

				<div className="avatar">
					<div className="tit">
						<p className="btxt">아바타</p>
						<div  className="more-btn"><span>more</span></div>
					</div>
					<div className="lists">
						<div className="btns">
							<p className="avatar-btn cursor-pointer prev" ><img src="../images/Main/avatar-Pbtn.png" alt="이전"/></p>
							<p className="avatar-btn cursor-pointer next" ><img src="../images/Main/avatar-Nbtn.png" alt="다음"/></p>
						</div>
						<ul className="avatar-list">

              <li style={{height: "85px"}}>
								<div >
									<span className="img">
                    <img src="../images/avatar/avatar5.png" alt="" />
                  </span>
									<span className="btxt">댄디보이</span>
									<span className="cash">100,000</span>
									<span className="gold">100,000</span>
								</div>
							</li>

	
 


							{/* 


 


							<li style="    height: 85px;">
								<a href="javascript:buy('/shop/buy.asp?idx=4&amp;opt=avatar');">
									<span className="img"><img src="./images/avatar/avatar3.png" alt=""></span>
									<span className="btxt">도도녀</span>
									<span className="cash">30,000</span>
									<span className="gold">30,000</span>
								</a>
							</li>


 


							<li style="    height: 85px;">
								<a href="javascript:buy('/shop/buy.asp?idx=7&amp;opt=avatar');">
									<span className="img"><img src="./images/avatar/avatar6.png" alt=""></span>
									<span className="btxt">나이스가이</span>
									<span className="cash">300,000</span>
									<span className="gold">300,000</span>
								</a>
							</li> */}

			
			

 
						</ul>
					</div>
				</div>
				
				<div className="noti-event">
					<div className="taps" style={{height: "90px"}}>
						<div className="wrap on">
							<div className="tab">
                <Link to="/notice" className="white">공지사항</Link>
              </div>
							<div className="cnt">
								<ul>
 
								</ul>
							</div>
						</div>
						<div className="wrap">
							<div className="tab">
                <Link to="/event" className="white">이벤트</Link>
              </div>
							<div className="cnt">
								<ul>


									 
								</ul>
							</div>
						</div>
					</div>
				</div>

				<div className="bownload">
          <div >
            <img src="../images/Main/down_pc.png" alt="게임 다운로드"/>
          </div>
        </div>
				
			</div>
  );
}

export default LeftBox;
