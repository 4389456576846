import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import axios from "axios";

import { isEmpty, toastr } from '../../utils';
import { api_url } from '../../utils/keys';

// const imgMenus = [
//   {
//     name: "홀덤",
//     imgPath: "../images/Main/game-sam03.jpg",
//     path: "/",
//   },
//   {
//     name: "탑게임 슬롯",
//     imgPath: "../images/Main/game-sam10.jpg",
//     path: "/topgame",
//   },
// ]

function ImageMenu() {
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  const [providerResult, setProviderResult] = useState([]);

  const onMenuClick = (e) => {
    if(!isAuthenticated){
      e.preventDefault();
      toastr.warning("로그인하시고 이용해주세요.");
    }
  }

  useEffect(() => {
    axios.post(api_url + "/api/slot/getProviderList", { api: "topgame" })
      .then(res => {
        // console.log('res: ', res);
        if (res.data.success) {
          if (!isEmpty(res.data.provider_result)) {
            setProviderResult(res.data.provider_result);
          }
        }
      })
      .catch((error) => {
        toastr.warning("잠시후 다시 시도해주세요.");
      })
  }, [isAuthenticated]);
  return (
    <div className="list">
      <ul>
        {
          providerResult.map((menu, index) => {
            return (
              <li  key={index}>
                <Link to="/topgame" onClick={onMenuClick}>
                  <span className="img">
                    <img src={menu.image} alt={menu.provider_name} />
                  </span>
                  <span className="btxt">{menu.provider_name}</span>
                </Link>
              </li>
            )
          })
        }
      </ul>
    </div>
  );
}

export default ImageMenu;
