import { Fragment, useState } from "react";
import { isEmpty, toastr } from "../../../utils";

function CustomerInput(props) {

  const { title, content, answer, setActive, newQuestion, onNewQuestion } = props;
  console.log('answer: ', answer);
  const [inputs, setInputs] = useState({
    questionTitle: "",
    questionContent: ""
  });
  const { questionTitle, questionContent } = inputs;

  const onChange = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    })
  }

  const onClose = () => {
    if (newQuestion) {
      onNewQuestion();
    }
    else {
      setActive(-1);
    }
  }
  
  const onQuestion = () => {
    if(isEmpty(questionTitle)){
      return toastr.warning("제목을 입력해주세요.")
    }
    
    if(isEmpty(questionContent)){
      return toastr.warning("내용을 입력해주세요.")
    }
    const params = {
      title: questionTitle,
      question: questionContent
    };
    props.onQuestion(params);
  }

  return (
    <Fragment>
      <div className="swal2-container swal2-center swal2-backdrop-show" style={{ overflowY: "auto" }} >
        <div className="swal2-popup-back" onClick={onClose}></div>
        <div aria-labelledby="swal2-title" aria-describedby="swal2-html-container"
          className="swal2-popup swal2-modal swal2-icon-info swal2-show" tabIndex="-1" role="dialog" aria-live="assertive"
          aria-modal="true" style={{ display: "grid" }}>
          <div className="swal2-icon swal2-info swal2-icon-show" style={{ display: "flex" }}>
            <div className="swal2-icon-content" style={{ color: "#ffd30b" }}>?</div>
          </div>
          <div className="swal2-actions" style={{ display: "block" }}>
            {
              newQuestion ?
                (
                  <div className="w-100">
                    <div className="input-group">
                      <label className="">제목</label>
                      <input
                        type="text"
                        className="w-100"
                        name="questionTitle"
                        value={questionTitle}
                        onChange={onChange} />
                    </div>
                    <div className="input-group">
                      <label>내용</label>
                      <textarea
                        className="w-100"
                        rows="6"
                        name="questionContent"
                        value={questionContent}
                        onChange={onChange} />
                    </div>
                    <div className="list d-flex justify-center w-100">
                      <div className="reg-btn question-btn" style={{ margin: "auto" }} onClick={onQuestion} onDoubleClick={e => e.preventDefault}>문의하기</div>
                    </div>
                  </div>
                )
                :
                (
                  (
                    <div className="w-100">
                      <div className="input-group">
                        <h2 className="swal2-title" id="swal2-title" style={{ display: "block", padding: "0", lineHeight: "30px" }}>{title}</h2>
                      </div>
                      <div className="input-group">
                        <label>문의내용</label>
                        <div className="question-text" dangerouslySetInnerHTML={{ __html: content }} />
                      </div>
                      <div className="input-group">
                        <label>답변</label>
                        <div className="question-text" dangerouslySetInnerHTML={{ __html: answer }} />
                      </div>
                    </div>
                  )
                )
            }
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default CustomerInput;
