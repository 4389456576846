import { Fragment } from "react";
import { isEmpty } from "../../utils";

function Input(props) {

  const {
    labelName,
    checkLoginId,
    errorMsg,
    placeholder,
    type,
    name,
    inputClassName,
    onChange,
    value,
    onCheckDuplication, 
    disabled, 
  } = props;

  return (
    <Fragment>
      <div className="d-flex input-group">
        <div className="input-label input-label-mark">{labelName}</div>
        <div className="position-relative input-box">
          <input type={type}
            placeholder={placeholder}
            className={inputClassName}
            name={name}
            value={value}
            onChange={onChange} 
            disabled={!isEmpty(disabled)}/>
          {checkLoginId && <div className="check-loginid" onClick={onCheckDuplication} onDoubleClick={e => e.preventDefault}>중복확인</div>}
        </div>
      </div>
      <div className="d-flex input-group">
        <div className="input-label"></div>
        {
          !isEmpty(errorMsg) &&
          <div className="cred pad5t">{errorMsg}</div>
        }
      </div>
    </Fragment>
  );
}

export default Input;
