import { useEffect, useState } from "react";
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { checkLoginIdstr, isEmpty, showMessage, toastr } from "../../utils";
import User from "../layout/User";
import Input from "./Input";
import { api_url } from "../../utils/keys";
import { history } from "../../helpers/history";
import { userActions } from "../../store";

const bankList = [
  {
    bankName: "국민은행",
    img: "6568127bde886f2ff3e44664.png",
  },
  {
    bankName: "SC제일은행",
    img: "656815078aafa43c876bdfed.png",
  },
  {
    bankName: "광주은행",
    img: "656815118aafa43c876be012.png",
  },
  {
    bankName: "농협은행",
    img: "656815198aafa43c876be037.png",
  },
  {
    bankName: "부산은행",
    img: "656815228aafa43c876be03d.png",
  },
  {
    bankName: "새마을금고",
    img: "6568152a8aafa43c876be062.png",
  },
  {
    bankName: "신한은행",
    img: "656815318aafa43c876be087.png",
  },
  {
    bankName: "우리은행",
    img: "6568153a8aafa43c876be0ac.png",
  },
  {
    bankName: "우체국",
    img: "6568153f8aafa43c876be0af.png",
  },
  {
    bankName: "전북은행",
    img: "656815448aafa43c876be0d1.png",
  },
  {
    bankName: "제주은행",
    img: "656815478aafa43c876be0d4.png",
  },
  {
    bankName: "카카오뱅크",
    img: "6568154b8aafa43c876be0f6.png",
  },
  {
    bankName: "하나은행",
    img: "656815568aafa43c876be11b.png",
  },
  {
    bankName: "한국씨티은행",
    img: "6568155a8aafa43c876be11e.png",
  }
]

function MyPage() {
  const isAuthenticated = useSelector(state => state.user.isAuthenticated);
  const user = useSelector(state => state.user.data);
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState({
    loginid: '',
    username: '',
    oldPwd: '',
    loginpwd: '',
    loginpwd_confirm: '',
    // birthday: '',
    // gender: '1'
  });
  const {
    loginid, 
    oldPwd,
    loginpwd,
    loginpwd_confirm,
    username,
    bankname,
    accountnumber,
    mastername,
    withdrawPassword,
    withdrawPassword_confirm,
    telnum,
    // birthday,
    // gender
  } = inputs;

  const [error, setError] = useState({})

  const onChange = (e) => {
    const { value, name } = e.target; // 우선 e.target 에서 name 과 value 를 추출
    setInputs({
      ...inputs, // 기존의 input 객체를 복사한 뒤
      [name]: value // name 키를 가진 값을 value 로 설정
    });
  };

  const onReset = () => {
    setInputs({
      ...inputs,
      oldPwd: '',
      loginpwd: '',
      loginpwd_confirm: '',
    })
  };

  const onChangeInfo = e => {
    e.preventDefault();

    let params = {
      username,
    }

    let err = {};
    if (isEmpty(username)) err.username = '닉네임을 입력해주세요.';
    // if (isEmpty(telnum) || telnum.length < 11) err.telnum = '핸드폰번호를 입력해주세요.';
    // if (isEmpty(bankname)) err.bankname = '은행명을 입력해주세요.';
    // if (isEmpty(mastername)) err.mastername = '예금주를 입력해주세요.';
    // if (isEmpty(accountnumber)) err.accountnumber = '계좌번호를 입력해주세요.';
    if (!isEmpty(err)) return setError(err);

    setError({});
    axios.post(api_url + "/api/users/userinfo_change", params)
      .then(res => {
        const { success, errors, message } = res.data;
        if (success) {
          showMessage(message);
        }
      })
      .catch(err => {
        console.log(err)
        if (err.response && err.response.status === 401) {
          dispatch(userActions.logoutUser());
        } else {
        toastr.warning('잠시후 다시 시도해주세요.');
        }
      })
  }

  const onChangePassword = e => {
    e.preventDefault();

    let params = {
      oldPwd,
      loginpwd,
      loginpwd_confirm,
    }

    let err = {};

    if (isEmpty(oldPwd)) err.oldPwd = '현재 비번을 입력해주세요.';
    if (isEmpty(loginpwd)) err.loginpwd = '새 비번을 입력해주세요.';
    if (isEmpty(loginpwd_confirm)) err.loginpwd_confirm = '새 비번확인을 입력해주세요.';
    if (!isEmpty(err)) return setError(err);

    setError({});
    axios.post(api_url + "/api/users/passwordchange", params)
      .then(res => {
        const { success, errors, message } = res.data;
        if (success) {
          showMessage(message);
          onReset();
        }
      })
      .catch(err => {
        console.log(err)
        if (err.response && err.response.status === 401) {
          dispatch(userActions.logoutUser());
        } else {
        toastr.warning('잠시후 다시 시도해주세요.');
        }
      })
  }

  useEffect(() => {
    if(!isEmpty(user)){
      setInputs({
        ...inputs, 
        loginid: user.loginid,
        username: user.username 
      })
    }
  }, [user.username])

  return (
    <div className="msec-core">
      <div className="register-border">
        <User nameKor="마이페이지" name="" />

        <div className="abatar-item">

          <div className="slot register my-page">
            <div className="list d-flex">
              <div className="register-div">

                <Input
                  labelName="아이디"
                  checkLoginId={false}
                  errorMsg={""}
                  placeholder=""
                  type="text"
                  name="username"
                  inputClassName=""
                  value={loginid} 
                  disabled={true}/>

                <Input
                  labelName="닉네임"
                  checkLoginId={false}
                  errorMsg={error.username}
                  placeholder="닉네임을 입력해주세요."
                  type="text"
                  name="username"
                  inputClassName=""
                  onChange={onChange}
                  value={username} />


                <div className="list d-flex justify-end">
                  <div className="reg-btn" onClick={onChangeInfo} onDoubleClick={e => e.preventDefault}>회원정보 변경</div>
                </div>
              </div>
              <div className="register-div">

                <Input
                  labelName="현재 비밀번호"
                  checkLoginId={false}
                  errorMsg={error.oldPwd}
                  placeholder="현재 비밀번호확인을 입력해주세요."
                  type="password"
                  name="oldPwd"
                  inputClassName=""
                  onChange={onChange}
                  value={oldPwd} />

                <Input
                  labelName="새 비밀번호"
                  checkLoginId={false}
                  errorMsg={error.loginpwd}
                  placeholder="비밀번호를 입력해주세요."
                  type="password"
                  name="loginpwd"
                  inputClassName=""
                  onChange={onChange}
                  value={loginpwd} />

                <Input
                  labelName="새 비번확인"
                  checkLoginId={false}
                  errorMsg={error.loginpwd_confirm}
                  placeholder="비밀번호확인을 입력해주세요."
                  type="password"
                  name="loginpwd_confirm"
                  inputClassName=""
                  onChange={onChange}
                  value={loginpwd_confirm} />

                <div className="list d-flex justify-end">
                  <div className="reg-btn" onClick={onChangePassword} onDoubleClick={e => e.preventDefault}>비밀번호 변경</div>
                </div>
              </div>
            </div>

          </div>


        </div>
      </div>
    </div>
  );
}

export default MyPage;
