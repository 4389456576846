import { configureStore } from '@reduxjs/toolkit';

import { userReducer } from './user.slice';
import { topgameReducer } from './topgame.slice';
import { moreReducer } from './more.slice';
import { pageReducer } from './page.slice';

export * from './user.slice';
export * from './topgame.slice';
export * from './more.slice';
export * from './page.slice';

export const store = configureStore({
    reducer: {
        user: userReducer,
        topgame: topgameReducer, 
        more: moreReducer,
        page: pageReducer,
    },
});